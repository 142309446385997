@import '../../../styles/customMediaQueries.css';

.socialButtonWrapper {
  margin-bottom: 6px;

  @media (--viewportMedium) {
    max-width: 200px;
    margin-top: 8px;
  }
}
